<template>
  <div>
    <!-- Note List UI Element Here -->
    <archived-note-list />
  </div>
</template>

<script>
import ArchivedNoteList from "../../components/keep/note/ArchivedNoteList.vue";

export default {
  name: "ArchivedNoteListView",
  components: { ArchivedNoteList },
};
</script>